<template>
  <div class="tips-free">
    <template v-if="showFree">
      <TipsInfo @show="show" @changeView="changeView" v-if="showFree" />
    </template>
    <template v-if="showDesglose">
      <TipsDesglose @show="show" :isTips="true" @changeView="changeView" />
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
  name: 'GMakingMoneyTips',
  components: {
    TipsInfo: () => import('@/components/Sections/GolTipster-v2/Tips/TipsInfoPremium'),
    TipsDesglose: () => import('@/components/Sections/GolTipster-v2/Tips/TipsDesglose'),
  },
  data() {
    return { showDesglose: true, showFree: false };
  },

  computed: {
    ...mapState(['displayWidth']),
    isMobile() {
      return this.displayWidth < 464;
    },
  },
  watch: {},
  methods: {
    ...mapActions('betsGeneral', ['getTipsFree']),
    changeView(opt) {
      if (opt == 'free') {
        this.showDesglose = false;
        this.showFree = true;
      } else {
        this.showDesglose = true;
        this.showFree = false;
      }
    },
    show() {
      this.showDesglose = !this.showDesglose;
      this.$emit('show');
    },
  },
  async created() {
    await this.getTipsFree();
  },
};
</script>

<style scoped lang="scss"></style>
